import { useNavigate } from "react-router-dom";

const Button = ({ ctaText, disabled }) => {
  const navigate = useNavigate();
  const redirect = () => {
    navigate("/registration-form");
  };

  return (
    <button className="cta" onClick={redirect} disabled={disabled}>
      {ctaText}
    </button>
  );
};

export default Button;
