import ImportantText from "../important-text";

const IntroText = () => {
  return (
    <section>
      <ImportantText>
        SEI APPASSIONATO DI <strong>CALCIO</strong>?
      </ImportantText>
      <br />
      <ImportantText>
        E SE TI DICESSI CHE ABBIAMO IL SEGRETO PER TRASFORMARE LA PIU' COMUNE
        DELLE PASSIONI IN PROFITTI <strong>REALI E COSTANTI</strong> NEL TEMPO?
      </ImportantText>
    </section>
  );
};

export default IntroText;
