import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

const Carousel = () => {
  return (
    <Swiper
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={"1.7"}
      loop
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      pagination={true}
      modules={[EffectCoverflow]}
      className="mySwiper super-spacer"
    >
      <SwiperSlide>
        <img src="/wins/SLIDE-1.jpg" alt="slide-1" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="/wins/SLIDE-2.jpg" alt="slide-2" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="/wins/SLIDE-3.jpg" alt="slide-3" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="/wins/SLIDE-4.jpg" alt="slide-4" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="/wins/SLIDE-5.jpg" alt="slide-5" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="/wins/SLIDE-6.jpg" alt="slide-6" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="/wins/SLIDE-7.jpg" alt="slide-7" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="/wins/SLIDE-8.jpg" alt="slide-8" />
      </SwiperSlide>
      <SwiperSlide>
        <img src="/wins/SLIDE-9.jpg" alt="slide-9" />
      </SwiperSlide>
    </Swiper>
  );
};

export default Carousel;
