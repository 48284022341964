import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import ImportantText from "../components/important-text";
import Button from "../components/button";

const Form = () => {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    check: false,
  });
  const [message, setMessage] = useState("");

  const [isDisabled, setIsDisabled] = useState(true);

  const [formError, setFormError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let name = userData.name;
    let email = userData.email;
    let phone = userData.phone;

    if (!name || !email || !phone || !userData?.check) {
      setFormError("Please fill in all the fields correctly.");
      return;
    }

    const { error } = await supabase
      .from("users")
      .insert([{ name, email, phone }]);

    if (error) {
      setFormError("c'è stato un errorre, riprovca a breve.");
      return;
    }

    setFormError(null);
    setUserData({});
    setMessage(
      "Grazie per esserti iscritto! Verrai ricontattato dal nostro team il prima possibile!"
    );
  };

  // REGEX
  const phoneRegex = new RegExp(/^3[0-9]{8,9}$/);
  const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);

  const validPhone = phoneRegex.exec(userData.phone);
  const validEmail = emailRegex.exec(userData.email);

  useEffect(() => {
    if (
      userData.name !== "" &&
      userData.email !== "" &&
      userData.phone !== "" &&
      userData.check &&
      validPhone !== null &&
      validEmail !== null
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [userData, validPhone, validEmail]);

  return (
    <div className="page">
      <section>
        <ImportantText classname={"super-spacer"}>
          <strong>ENTRA</strong> A FAR PARTE DELLA COMMUNITY
        </ImportantText>
      </section>
      <form onSubmit={handleSubmit}>
        <div className="input">
          <label htmlFor="name">Nome</label>
          <input
            type="text"
            name="name"
            value={userData?.name}
            onChange={(e) => setUserData({ ...userData, name: e.target.value })}
            placeholder="Inserisci il tuo nome"
          />
        </div>
        <div className="input">
          <label htmlFor="name">Email</label>
          <input
            type="text"
            name="email"
            value={userData?.email}
            onChange={(e) =>
              setUserData({ ...userData, email: e.target.value })
            }
            placeholder="Inserisci la tua e-mail"
          />
        </div>
        <div className="input">
          <label htmlFor="phone">Telefono</label>
          <input
            type="number"
            name="phone"
            value={userData?.phone}
            onChange={(e) =>
              setUserData({ ...userData, phone: e.target.value })
            }
            placeholder="Inserisci il tuo numero di telefono"
          />
        </div>

        <div className="checkbox">
          <input
            type="checkbox"
            onChange={(e) =>
              setUserData({ ...userData, check: e.target.checked })
            }
          />
          <div>Acconsento al trattemento dei dati personali.</div>
        </div>

        {message !== "" ? (
          <strong>{message}</strong>
        ) : (
          <Button ctaText="INIZIA AD INVESTIRE" disabled={isDisabled} />
        )}

        {formError && <p className="error">{formError}</p>}
      </form>
    </div>
  );
};

export default Form;
