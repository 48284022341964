import { BrowserRouter, Routes, Route } from "react-router-dom";

// pages
import Home from "./pages/Home";
import Form from "./pages/Form";

function App() {
  return (
    <BrowserRouter>
      <nav>
        <img src="/logos/logo-dark.png" alt="The Team - Logo" />
      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/registration-form" element={<Form />} />
      </Routes>
      <footer>
        <img src="/logos/logo-light.png" alt="The Team - Logo" />
        <div>© 2023 - THE TEAM All rights reserved</div>
      </footer>
    </BrowserRouter>
  );
}

export default App;
