import { useNavigate } from "react-router-dom";
import Button from "../components/button";
import Carousel from "../components/carousel";
import DarkSection from "../components/dark-section";
import ImportantText from "../components/important-text";
import IntroText from "../components/intro-text";

const Home = () => {
  const navigate = useNavigate();
  const redirect = () => {
    navigate("/registration-form");
  };

  return (
    <div className="page">
      <IntroText />
      <DarkSection>
        <div className="spacer">
          Guarda il video per scoprire la strategia che ci ha permesso di{" "}
          <strong>ottenere il successo</strong>.
        </div>
        <img className="video-img spacer" src="/video.png" alt="video" />
        <ImportantText>
          Questo video non è per tutti, guardalo solo se cerchi:
        </ImportantText>
        <ul className="spacer">
          <li>
            Più <strong>tempo</strong> libero.
          </li>
          <li>
            Un mezzo per realizzare i tuoi <strong>sogni</strong>.
          </li>
          <li>
            Un lavoro che ti faccia passare il tempo{" "}
            <strong>divertendoti</strong>.
          </li>
          <li>
            Convertire in guadagno una tua <strong>passione</strong>.
          </li>
          <li>
            Un nuovo modo per accrescere le tue <strong>rendite mensili</strong>
            .
          </li>
        </ul>
        <Button ctaText={"PRE REGISTRATI"} />
      </DarkSection>
      <section>
        <ImportantText classname={"spacer"}>Chi siamo?</ImportantText>
        Siamo <i>THE TEAM</i> , una startup fondata da ragazzi che fanno questo
        mestiere da ormai più di 3 anni.
        <div className="mini-spacer" />
        Dopo corsi su corsi e migliaia di euro investiti sulla formazione,
        abbiamo deciso di <strong>rivoluzionare</strong> il mondo degli
        investimenti sportivi.
        <div className="mini-spacer" />
        Non siamo qui per mostrarvi cose che potete trovare gratuitamente su
        YouTube, siamo qui per insegnarvi il metodo e il ragionamento che c’è
        dietro a questo lavoro.
        <div className="spacer" />
        <Button ctaText={"VUOI CONOSCERE IL SEGRETO?"} />
      </section>
      <DarkSection>
        <ImportantText classname={"spacer"}>
          ABBIAMO SCOPERTO IL <strong>SEGRETO</strong> CHE TI PERMETTE DI
          ABBATTERE TUTTI I LIMITI NEL CAMPO DEL{" "}
          <strong>TRADING SPORTIVO</strong>
        </ImportantText>
        <Carousel />
        <p className="spacer">
          Vuoi anche tu queste <strong>vincite</strong>?
        </p>
        <Button ctaText={"CLICCA QUI"} />
      </DarkSection>
      <section>
        <ImportantText classname={"spacer"}>
          Cos'è il betting exchange?
        </ImportantText>
        Il Trading Sportivo è un punto d’incontro tra due mondi, quello del{" "}
        <strong>Betting Tradizionale</strong> e quello del{" "}
        <strong>Trading Finanziario</strong>.
        <div className="mini-spacer" />
        Se nel Trading Finanziario hai la possibilità di guadagnare speculando
        sulla variazione del valore di qualsiasi bene, nel{" "}
        <strong>Trading Sportivo</strong> guadagni sulle variazioni delle quote.
        <div className="mini-spacer" />
        Se nel Betting Tradizionale devi attendere la fine dell’evento per poter
        incassare il tuo profitto o per accettare la tua perdita, nel{" "}
        <strong>Trading Sportivo</strong> hai la possibilità di uscire dal
        mercato in qualsiasi momento, che sia in profitto o meno.
        <div className="super-spacer" />
        <ImportantText>
          SE ANCHE TU PENSI CHE PUOI CAMBIARE LA TUA VITA{" "}
          <div onClick={redirect}>
            <strong>
              <u>CLICCA QUI</u>
            </strong>
            .
          </div>
        </ImportantText>
      </section>
    </div>
  );
};

export default Home;
